<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :items="export_number_data"
      :items-per-page="10"
      item-key="Number"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="h-screen"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <h4 class="text-h5 font-weight-bold orange--text text--darken-4">
            {{ getBetTypeText(BetType) }}
          </h4>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="blue" @click="Confirm()">
            <v-icon left>
              mdi-send-circle-outline
            </v-icon>
            ยืนยันส่งออก
          </v-btn>
        </v-toolbar>
        <!-- <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ profit_count }}</strong> รายการ
        </v-alert> -->
      </template>
      <template slot="header">
        <thead>
          <tr>
            <th
              colspan="1"
              class="h_id"
              style="width: 8%;border-left: 1px solid;border-top: 1px solid;"
            >
              เลข
            </th>
            <th
              colspan="1"
              class="text-right"
              style="width: 10%;border-left: 1px solid;border-top: 1px solid;"
            >
              คงเหลือ
            </th>
            <th
              colspan="1"
              class="text-right"
              style="width: 10%;border-left: 1px solid;border-top: 1px solid;"
              v-for="item in bookie_data"
              :key="item.id"
            >
              {{ item.Name }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ item }">
        <!-- @contextmenu="popup_menu($event, item)" -->
        <tr>
          <!-- @touchstart="touch = true"
          @click="row_click($event, item)"
          @contextmenu="popup_menu($event, item)"
          :class="{ 'pink lighten-5': item.Number === selectedId }" -->
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <!-- <td :class="[id_column, getIDColor(item.id)]"> -->
          <!-- <td :class="id_column">
            {{ item.CNC }}
          </td> -->
          <td
            align="end"
            v-for="index in export_number_data_count"
            :key="index"
            @click="PriceClick(item.Number, index)"
          >
            {{ getColumnText(item.Number, index) }}
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <tr class="black--text"></tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
//import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import PopupMenu from "@/components/Data/Export/Export_Decide/Genaral/Popup_Menu";

export default {
  name: "Export_Final_Datatable",

  components: {
    PopupMenu,
  },
  created() {
    this.BetType = this.$route.query.bettype;
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    bookie_data() {
      return this.$store.getters["export_final/Bookies"];
    },
    export_number_data() {
      return this.$store.getters["export_final/ExportNumber"];
    },
    export_number_data_count() {
      return this.$store.getters["export_final/Bookies"].length + 2;
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BetType: this.BetType,
      };
      await this.$store.dispatch("export_final/get_list", credentials).then(
        (response) => {
          //console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async Confirm() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BetType: this.BetType,
      };
      await this.$store.dispatch("export_final/confirm", credentials).then(
        (response) => {
          //console.log(response);
          this.$router.push({ path: "export_begin" });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getBetTypeText(betType) {
      const statusText = [
        "รวมบน",
        "3 ตัวบน",
        "3 ตัวโต๊ด",
        "2 ตัวบน",
        "2 ตัวล่าง",
        "คู่โต๊ด",
        "",
        "พวง",
        "",
        "จม บ.น.",
        "จม บ.ก.",
        "จม บ.ท.",
        "จม. ล.น.",
        "จม. ล.ท.",
        "ลอยบน",
        "ลอยล่าง",
        "คู่หน้า",
        "คู่ถ่าง",
      ];
      if (betType == -1) return "รวมล่าง";
      if (betType == 0) return "รวมบน";
      return statusText[parseInt(betType)];
    },
    getIDColor(betType) {
      const statusText = [
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "indigo",
        "",
        "indigo",
        "",
        "indigo",
        "indigo",
        "indigo",
        "teal",
        "teal",
        "indigo",
        "teal",
        "indigo",
        "indigo",
      ];
      if (betType == -1) return "pink";
      if (betType == 0) return "pink";
      return statusText[parseInt(betType)];
    },
    getStatusText(status) {
      const statusText = ["ปกติ", "ระงับ"];
      return statusText[parseInt(status)];
    },
    getColumnText(Number, ColumnID) {
      let EXPNUM = this.$store.getters["export_final/ExportNumber"];
      for (let i = 0; i < EXPNUM.length; i++) {
        if (EXPNUM[i].Number == Number) {
          // console.log(EXPNUM[i]);
          if (ColumnID == 1) {
            return Number;
          } else if (ColumnID == 2) {
            return EXPNUM[i].PriceSum;
          } else {
            let BookieID = this.$store.getters["export_final/Bookies"][
              ColumnID - 3
            ].id;
            return EXPNUM[i][BookieID];
          }
        }
      }
    },
    PriceClick(Number, index) {
      // alert(index);
    },
    row_click: function(e, item) {
      if (this.selectedId == item.COL) {
        if (this.touch == true) this.popup_menu(e, item);
      }

      this.selectedId = item.COL;
      this.$set(item, "selected", true);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(this.BetType, item.COL);
        this.selectedId = item.COL;
        //console.log(item);
      });
    },
  },
  data: () => ({
    selectedId: -1,
    BetType: 0,
    Col: 0,
    touch: false,
    Page: 1,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  color: white;
  text-align: left !important;
  padding-top: 3px !important;
  /* position:absolute;  */
  border-color: rgb(245, 242, 242) !important;
  background: rgb(127, 101, 143) !important;
  position: sticky !important;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(250, 242, 255) !important;
  color: rgb(43, 42, 42) !important;
  padding-top: 0px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  font-size: 16px !important;
}

::v-deep table {
  background-color: #fafafa;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(0, 0, 0);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.9em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
