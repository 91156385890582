<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('export')">
          <v-list-item-icon>
            <v-icon color="red">mdi-chart-sankey</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ตัดส่งเจ้า</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('del')">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="red--text">ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="light-blue darken-4">mdi-percent-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>อั้น</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('page')">
          <v-list-item-icon>
            <v-icon color="success">mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ดูรายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-menu>
    <ShowDecide ref="ShowDecide"/>
  </div>
</template>

<script>

import ShowDecide from "@/components/Data/Export/Export_Decide/Genaral/ShowDecide";

export default {
  name: "PopupMenu",

  components: {
    ShowDecide,
  },

  methods: {
    Show(BetType, Keep) {
      this.showMenu = true;
      this.BetType = BetType;
      this.Keep = Keep;
    },

    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        case "export":
            // this.$emit("ShowDecideModal",this.COL)
            this.$refs.ShowDecide.Show(this.BetType,this.Keep);
          break;
        case "send_back":
          if (this.LineStatus == 4) {
            this.$confirm("กดยืนยันเพื่อ เรียกคืน บรรทัดที่ " + this.LineText)
              .then(() => {
                this.$emit("resend", this.id);
              })
              .catch(() => {});
          } else {
            this.$confirm("กดยืนยันเพื่อ ตีกลับ บรรทัดที่ " + this.LineText)
              .then(() => {
                this.$emit("send_back", this.id);
              })
              .catch(() => {});
          }

          break;
        case "page":
          this.$router.push({
            path: "page",
            query: {
              line_id: this.LineID,
              agent_id: this.AgentID,
              page_number: this.id,
            },
          });

          //this.$router.push("/Agent_Data");
          break;
      }
    },
  },
  data: () => ({
    BetType: null,
    Keep: null,
    id: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
};
</script>

<style></style>
