<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="1000px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="deep-purple" dense>
        <v-toolbar-title>แสดงการตัด</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-actions class="justify-center">
        <!-- <v-spacer></v-spacer> -->
        <v-btn color="purple" dark @click="OK()">
          ตกลง
        </v-btn>
      </v-card-actions>
      <v-divider class="mt-1"></v-divider>

      <div
        style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
        unselectable="on"
        onselectstart="return false;"
        onmousedown="return false;"
      >
        <v-data-table
          :items="decide_data"
          item-key="id"
          single-select
          class="elevation-1"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
          height="h-screen"
          fixed-header
          dense
        >
          <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->

          <template slot="header">
            <thead dense>
              <tr>
                <th style="">ยอดรวม</th>
                <th class="text-right">{{ NUM2STR(decide_detail.BSUM) }}</th>
                <th style="">เปอร์เซ็นต์</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.BPERCENT) }}
                </th>
                <th style="">คงเหลือ</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.BSUM - decide_detail.BPERCENT) }}
                </th>
              </tr>
              <tr>
                <th style="">ส่งออก</th>
                <th class="text-right">{{ NUM2STR(decide_detail.ESUM) }}</th>
                <th style="">เปอร์เซ็นต์</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.EPERCENT) }}
                </th>
                <th style="">คงเหลือ</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.ESUM - decide_detail.EPERCENT) }}
                </th>
              </tr>
              <tr>
                <th style="">ยอดหลังส่ง</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.BSUM - decide_detail.ESUM) }}
                </th>
                <th style="">เปอร์เซ็นต์</th>
                <th class="text-right">
                  {{ NUM2STR(decide_detail.BPERCENT - decide_detail.EPERCENT) }}
                </th>
                <th style="">คงเหลือ</th>
                <th class="text-right">
                  {{
                    NUM2STR(
                      decide_detail.BSUM -
                        decide_detail.BPERCENT -
                        decide_detail.ESUM +
                        decide_detail.EPERCENT
                    )
                  }}
                </th>
              </tr>
              <tr>
                <th style="">ตั้งขาดทุน</th>
                <th class="text-right">{{ NUM2STR(Keep) }}</th>
                <th style="">หรือเท่ากับ</th>
                <th class="text-right">
                  {{
                    NUM2STR(
                      (Keep +
                        (Keep * decide_detail.HLPercent) / 100 +
                        (Keep * decide_detail.HAPercent) / 100) /
                        decide_detail.HRATE
                    )
                  }}
                </th>
                <th colspan="2">
                  ขาดทุนคือ จ่ายลูกค้า + เปอร์เซ็นต์ - เงินได้จากเจ้า ถูก
                  เปอร์เซ็นต์ + เงินตีออก
                </th>
              </tr>
              <tr>
                <th colspan="1" class="h_id" style="width: 8%;">
                  เลข
                </th>
                <th
                  colspan="1"
                  class="h_num text-center grey lighten-2 white-space: normal"
                  style="width: 10%;"
                >
                  ขาดทุนก่อนตัด
                </th>
                <th
                  class="h_betprice text-center grey lighten-4 white-space: normal"
                  colspan="1"
                  style="width: 10%;"
                >
                  ต้องตีให้ได้กำไร
                </th>
                <th
                  class="h_status text-center grey lighten-2"
                  colspan="1"
                  style="width: 10%;"
                >
                  ตีออกได้
                </th>
                <th
                  class="h_status text-center grey lighten-2"
                  colspan="1"
                  style="width: 10%;"
                >
                  ยอดแทง
                </th>
                <th
                  class="h_status text-center grey lighten-2"
                  colspan="1"
                  style="width: 10%;"
                >
                  ยอดตี
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{ item }">
            <!-- @contextmenu="popup_menu($event, item)" -->
            <tr>
              <!-- <td :class="id_column">{{ item.id }}</td> -->
              <td :class="id_column">
                {{ item.NUM }}
              </td>
              <td align="end" class="red--text">
                {{ NUM2STR(item.LOSS) }}
              </td>
              <td align="end" class="purple--text">
                {{ NUM2STR(item.NEED) }}
              </td>
              <td align="end" class="green--text text--darken-4">
                {{ NUM2STR(item.WIN_SUM) }}
              </td>
              <td align="end">
                {{ NUM2STR(item.BET_SUM) }}
              </td>
              <td align="end">
                {{ NUM2STR(item.EXP_PRICE) }}
              </td>
            </tr>
            <tr>
              <td colspan="6" align="left">{{ item.PROCESS }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- <v-card-item>
        เลข ยอดก่อนตัด ต้องเอาออก เอาออกได้ process
      </v-card-item> -->
      <!-- <v-card-item>
        <v-card-title>This is a title</v-card-title>

        <v-card-subtitle>This is a subtitle</v-card-subtitle>
      </v-card-item>

      <v-card-text>
        This is content
      </v-card-text> -->
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowDecide",

  components: {},
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    decide_data() {
      return this.$store.getters["export_decide/ExportDecideSelect"];
    },
    decide_detail() {
      return this.$store.getters["export_decide/ExportDecideDetail"];
    },
  },
  methods: {
    Show(BetType, Keep) {
      this.dialog = true;
      this.Keep = Keep;
      this.BetType = BetType;
      this.fetch_table();
    },
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BetType: this.BetType,
        Keep: this.Keep,
      };
      await this.$store
        .dispatch("export_decide/get_export_decide_select", credentials)
        .then(
          (response) => {
            //console.log(response);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    async OK() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BetType: this.BetType,
        Keep: this.Keep,
      };
      await this.$store
        .dispatch("export_decide/get_export_process", credentials)
        .then(
          (response) => {
            //console.log(response);
            this.$router.push({
              path: "export_final",
              query: {
                bettype: this.BetType,
              },
            });
          },
          (error) => {
            console.log(error);
          }
        );
    },
    NUM2STR(Num) {
      if (Num != "") {
        Num = parseInt(Num)
          .toString()
          .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
      return Num;
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    Keep: 0,
    BetType: 0,

    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  color: white;
  text-align: right !important;
  padding-top: 3px !important;
  /* position:absolute;  */
  border-color: rgb(245, 242, 242) !important;
  background: rgb(127, 101, 143) !important;
  position: sticky !important;
  left: 0;
}
::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(0, 0, 0);
  font-weight: normal;
  border-right: 1px solid;
  border-color: lightgray;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.9em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
