<template>
  <div>
    <v-app-bar dense fixed app color="pink darken-4" dark>
      <v-btn icon to="/menu_data">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>วิเคราะห์ก่อนตัด</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-breadcrumbs :items="bc_items" class="grey lighten-3 pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <component v-bind:is="component" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import GenaralTable from "@/components/Data/Export/Export_Final/Genaral/Datatable";

export default {
  name: "Line_Data",
  mixins: [globalFunctionMixin],
  components: {
    GenaralTable,
  },
  mounted() {
    this.ckLogin();
    this.bc_items[0].to = { path: "export_begin" };
    this.bc_items[1].text = " วิเคราะห์ก่อนตัด";
    this.bc_items[1].to = {
      path: "export_decide",
      query: {
        bettype: this.$route.query.bettype,
      },
    };
    this.bc_items[2].text = " ยืนยัน";
  },
  data: () => ({
    component: "GenaralTable",

    bc_items: [
      {
        text: "สรุปรวมก่อนส่งออก",
        disabled: false,
      },
      {
        text: "วิเคราะห์ก่อนตัด",
        disabled: false,
      },
      {
        text: "ยืนยัน",
        disabled: true,
      },
    ],

    BetType: 0,
    Col: 0,
  }),

  methods: {
    // onTabChange(id) {
    //   switch (id) {
    //     case 1:
    //       this.component = GenaralTable;
    //       break;
    //     case 2:
    //       this.component = CurrentTable;
    //       break;
    //     case 3:
    //       this.component = AccountTable;
    //       break;
    //   }
    //   // alert(id)
    // },
  },
};
</script>

<style></style>
